import React from 'react';
import { PlatformTab, PlatformTabEmptyState } from 'docComponents';

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="reactnative" />
    </PlatformTab>
  );
};

export default ReactNativeTab;
