import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, ButtonRow, Button } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/button-row.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, ButtonRow: ButtonRow, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="buttons/button-row"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>Determines the space between the buttons.</Text>
            <List type="unordered">
              <li>
                <code>eighth</code>
              </li>
              <li>
                <code>quarter</code>
              </li>
              <li>
                <code>half</code>
              </li>
              <li>
                <code>one</code>
              </li>
              <li>
                <code>one-and-half</code>
              </li>
              <li>
                <code>two</code>
              </li>
              <li>
                <code>three</code>
              </li>
              <li>
                <code>four</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this buttonrow in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/guidelines/space" isDesignCodeLink>
                uniCSS space classes
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
